import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrDocumentListCreateDocument,
  HrDocumentListDeleteDocument,
  HrDocumentListGetByIdDocument,
  HrDocumentListPaginatedDocument,
  HrDocumentListListDocument,
  HrDocumentListUpdateDocument,
} from "api/generated";
import { Optional } from "config";

import {
  DocumentListByIdResponse,
  ICreateDocumentListArgs,
  IDocumentListArgs,
  IDocumentListModel,
  IDocumentListResponse,
  IDocumentListUpdateArgs,
  IDocumentModel,
} from "../types";

export async function getHrDocumentListPaginated(
  variables: IDocumentListArgs
): Promise<IDocumentListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrDocumentListPaginatedDocument,
      variables,
    });
    return res?.data?.hrDocumentListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createHrDocumentList(
  variables: ICreateDocumentListArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrDocumentListCreateDocument,
      variables,
    });
    return res?.data?.hrDocumentListCreate;
  } catch (error) {
    throw error;
  }
}

export async function getHrDocumentList(
  documentListId: number
): Promise<DocumentListByIdResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrDocumentListGetByIdDocument,
      variables: {
        documentListId,
      },
    });
    return res?.data?.hrDocumentListGetById;
  } catch (error) {
    throw error;
  }
}

export async function updateHrDocumentList(
  variables: IDocumentListUpdateArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrDocumentListUpdateDocument,
      variables,
    });
    return res?.data?.hrDocumentListUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteHrDocumentList(
  documentListId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrDocumentListDeleteDocument,
      variables: {
        documentListId,
      },
    });
    return Boolean(res?.data?.hrDocumentListDelete);
  } catch (error) {
    throw error;
  }
}

export async function getHrDocumentLists(): Promise<
  (Pick<IDocumentListModel, "name" | "documentListId"> & {
    documents: Pick<IDocumentModel, "documentId" | "name">[];
  })[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrDocumentListListDocument,
    });
    return res?.data?.hrDocumentListList ?? [];
  } catch (error) {
    throw error;
  }
}
