import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrHolidayCalendarGetByIdDocument,
  HrHolidayCalendarCreateDocument,
  HrHolidayCalendarDeleteDocument,
  HrHolidayCalendarListDocument,
  HrHolidayCalendarListPaginatedDocument,
  HrHolidayCalendarUpdateDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IHrHolidayCalendarListModel,
  IHrHolidayCalendarModel,
  IHrHolidayCalendarPaginatedResponse,
  IHrHolidayCalendarUpdateVars,
  IHrHolidayCalendarCreateVars,
  IHrHolidayCalendarPaginatedVars,
} from "./types";

export async function getHrHolidayCalendarList(): Promise<
  IHrHolidayCalendarListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrHolidayCalendarListDocument,
    });
    return res?.data?.hrHolidayCalendarList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrHolidayCalendarListPaginated(
  input: IHrHolidayCalendarPaginatedVars
): Promise<Optional<IHrHolidayCalendarPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrHolidayCalendarListPaginatedDocument,
      variables: input,
    });
    return res?.data?.hrHolidayCalendarListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getHrHolidayCalendarById(
  id: number
): Promise<IHrHolidayCalendarModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrHolidayCalendarGetByIdDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrHolidayCalendarGetById ?? {};
  } catch (error) {
    throw error;
  }
}

export async function createHrHolidayCalendar(
  input: IHrHolidayCalendarCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrHolidayCalendarCreateDocument,
      variables: input,
    });
    return res?.data?.hrHolidayCalendarCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateHrHolidayCalendar(
  input: IHrHolidayCalendarUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrHolidayCalendarUpdateDocument,
      variables: input,
    });
    return res?.data?.hrHolidayCalendarUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteHrHolidayCalendar(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrHolidayCalendarDeleteDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrHolidayCalendarDelete ?? false;
  } catch (error) {
    throw error;
  }
}
