import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrCurrencyListDocument,
  HrEmployeeCompensationGetByIdDocument,
  HrEmployeeCompensationHistoryListDocument,
  HrEmployeeCompensationLastHistoryDeleteDocument,
  HrEmployeeCompensationLastHistoryUpdateDocument,
  HrEmployeeCompensationListDocument,
  HrEmployeeCompensationUpdateBulkDocument,
  HrEmployeeCompensationUpdateDocument,
} from "api/generated";

import {
  IHrCurrencyListModel,
  IHrEmployeeCompensationHistoryListModel,
  IHrEmployeeCompensationLastHistoryUpdateVars,
  IHrEmployeeCompensationModel,
  IHrEmployeeCompensationUpdateBulkVars,
  IHrEmployeeCompensationUpdateVars,
} from "./types";

export async function getHrCurrencyList(): Promise<IHrCurrencyListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrCurrencyListDocument,
    });
    return res?.data?.hrCurrencyList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeCompensationHistoryList(
  employeeId: number
): Promise<IHrEmployeeCompensationHistoryListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeCompensationHistoryListDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrEmployeeCompensationHistoryList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeCompensationList(): Promise<
  IHrEmployeeCompensationModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeCompensationListDocument,
    });
    return res?.data?.hrEmployeeCompensationList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeCompensationByEmployee(
  employeeId: number
): Promise<IHrEmployeeCompensationModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeCompensationGetByIdDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrEmployeeCompensationGetById ?? {};
  } catch (error) {
    throw error;
  }
}

export async function updateHrEmployeeCompensation(
  input: IHrEmployeeCompensationUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeCompensationUpdateDocument,
      variables: input,
    });
    return res?.data?.hrEmployeeCompensationUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateBulkHrEmployeeCompensation(
  input: IHrEmployeeCompensationUpdateBulkVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeCompensationUpdateBulkDocument,
      variables: input,
    });
    return res?.data?.hrEmployeeCompensationUpdateBulk ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateHrEmployeeCompensationLastHistory(
  input: IHrEmployeeCompensationLastHistoryUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeCompensationLastHistoryUpdateDocument,
      variables: input,
    });
    return res?.data?.hrEmployeeCompensationLastHistoryUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteHrEmployeeCompensationLastHistory(
  employeeId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeCompensationLastHistoryDeleteDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrEmployeeCompensationLastHistoryDelete ?? false;
  } catch (error) {
    throw error;
  }
}
