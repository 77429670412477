export enum CustomPalette {
  white = "#FFFFFF",
  default_gray = "#EDEDED",
  dark_gray = "#F2F3F6",
  light_gray = "#FCFCFC",
  lighter_gray = "#FBFBFB",
  disable_gray = "#E5E7EC",
  disable_text_gray = "#BCBFC5",
  default_dark = "#051723",
  light_dark = "#0E2C40",
  golden = "#E4B72D",
  dark_golden = "#9f801f",
  error_main = "#ED4C5C",
  dark_blue = "#1D5B84",
  light_green = "#3ABF79",
  default_green = "#04CE0C",
  light_blue = "#6793B7",
  lighter_blue = "#509CCE",
  darker_blue = "#082436",
  red = "#ED4C5C",
  middle_blue = "#86AEC8",
  black = "#000000",
  darker_gray = "#BABBBE",
  purple = "#6F3C77",
  yellow = "#FFF886",
  orange = "#F28D01",
}

export enum StylingRelatedConstants {
  EXPANDED_SIDEBAR_WIDTH = "230px",
  SIDEBAR_LOGO_HIEGHT = "39px",
  NON_EXPANDED_SIDEBAR_WIDTH = "80px",
  BOTTOM_PADDING_SIDEBAR_RELATED_TO_BOTTOM_SECTION = "80px",
  HEIGHT_38 = "38px",
  TOPBAR_HEIGHT = "79px",
  PAGINATION_HEIGHT = "27px",
  MAIN_BOX_UPPER_MARGIN = "89px",
  MAIN_BOX_LOWER_MARGIN = "20px",
}

export enum LinearGradients {
  SECONDARY_LAYOUT = "linear-gradient(110.45deg, #071925 -7.94%, #1F577D 118.47%)",
  MAIN_LAYOUT = "linear-gradient(107.74deg, #D5D5D5 0%, #FFFFFF 99.08%)",
}

export enum FontWeights {
  _400 = 400,
  _500 = 500,
  _600 = 600,
  _700 = 700,
  _800 = 800,
  _900 = 900,
  NORMAL = "normal",
}

export enum FontFamilies {
  WORK_SANS = "Work Sans",
}
