import { Order } from "components/table/enums";
import { client, Nullable } from "config";
import { RequestTypes } from "./enums";

type QueryOrMutate<T extends RequestTypes> = T extends RequestTypes.MUTATE
  ? typeof client.mutate
  : typeof client.query;

export type RestOrGraph<T extends RequestTypes> = T extends RequestTypes.REST
  ? typeof fetch
  : QueryOrMutate<T>;

export type PaginationArgs = {
  limit?: number;
  page?: number;
};

export type NumberFilterBetweenType = {
  from: number;
  to: number;
};

export type DateFiltersBetweenType = {
  from: Date;
  to: Date;
};

export type NumberFiltersType = {
  eq?: number;
  ne?: number;
  in?: number[];
  between?: NumberFilterBetweenType;
};

export enum AggregationFilterTypeKeys {
  COUNT = "count",
  AVG = "avg",
  SUM = "sum",
  MIN = "min",
  MAX = "max",
}

export enum ConditionFiltersTypeKeys {
  CONTAINS = "contains",
  EQ = "eq",
  NE = "ne",
  IN = "in",
  BETWEEN = "between",
}

export type AggregationInputFilter = {
  count: NumberFiltersType;
  sum: NumberFiltersType;
  avg: NumberFiltersType;
  max: NumberFiltersType;
  min: NumberFiltersType;
};

export type StringFiltersType<T = string> = {
  contains?: T;
  eq?: T;
  ne?: T;
  in?: T[];
};

export type DateFilterType = {
  eq?: Date;
  ne?: Date;
  between?: DateFiltersBetweenType;
};

export type BooleanFilterType = {
  eq: boolean;
};

export type SortingTypes<T> = {
  orderBy: {
    field: T;
    direction: Order.ASC & Order.DESC;
  }[];
};

export interface ITokens {
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

export interface ICountryModel {
  countryName: string;
  countryId: number;
}

export interface IDeletableUpdatableNode {
  updatable: boolean;
  deletable: boolean;
}

export interface IAddressModel {
  addressId?: number;
  streetAddress?: Nullable<string>;
  streetAddressLine2?: Nullable<string>;
  city?: Nullable<string>;
  state?: Nullable<string>;
  postalCode?: Nullable<string>;
  country?: Nullable<ICountryModel>;
}

export interface ICreateAddressModel
  extends Omit<IAddressModel, "country" | "addressId"> {
  countryId?: number;
}

export interface IUpdateAddressModel extends Omit<IAddressModel, "country"> {
  countryId?: number;
}

export interface IEmailVars {
  subject: string;
  content?: string;
  files?: number[];
}

export interface IFile {
  file: any;
  fileName?: string;
}

export interface IUploadAttachmentReturnType {
  temporaryFileId: number;
  name: string;
}

export interface IUserInteractionTypes {
  userInteractionTypeId: number;
  type: string;
}

export interface ICurrencyModel {
  currencyId: number;
  name: string;
}

export interface ICommonListModel {
  id: number;
  name: string;
}
