import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrEmployeeLeaveApprovalListDocument,
  HrEmployeeLeaveGetByIdDocument,
  HrEmployeeLeaveHistoryCreateDocument,
  HrEmployeeLeaveHistoryDeleteDocument,
  HrEmployeeLeaveHistoryListDocument,
  HrEmployeeLeaveHistoryUpdateDocument,
  HrEmployeeLeaveListPaginatedDocument,
  HrEmployeeLeaveTypeListDocument,
  HrEmployeeLeaveUpdateDocument,
} from "api/generated";
import { ICommonListModel } from "api/services";
import { Optional } from "config";

import {
  IHrEmployeeLeaveHistoryListModel,
  IHrEmployeeLeaveModel,
  IHrEmployeeLeaveUpdateVars,
  IHrEmployeeLeavePaginatedVars,
  IHrEmployeeLeavePaginatedResponse,
  IHrEmployeeLeaveHistoryUpdateVars,
  IHrEmployeeLeaveHistoryCreateVars,
} from "./types";

export async function getHrEmployeeLeaveTypeList(): Promise<
  ICommonListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeLeaveTypeListDocument,
    });
    return res?.data?.hrEmployeeLeaveTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeLeaveApprovalList(): Promise<
  ICommonListModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeLeaveApprovalListDocument,
    });
    return res?.data?.hrEmployeeLeaveApprovalList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeLeaveHistoryList(
  employeeId: number
): Promise<IHrEmployeeLeaveHistoryListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeLeaveHistoryListDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrEmployeeLeaveHistoryList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeLeaveListPaginated(
  input: IHrEmployeeLeavePaginatedVars
): Promise<Optional<IHrEmployeeLeavePaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeLeaveListPaginatedDocument,
      variables: input,
    });
    return res?.data?.hrEmployeeLeaveListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeLeaveByEmployee(
  employeeId: number
): Promise<IHrEmployeeLeaveModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeLeaveGetByIdDocument,
      variables: {
        employeeId,
      },
    });
    return res?.data?.hrEmployeeLeaveGetById ?? {};
  } catch (error) {
    throw error;
  }
}

export async function updateHrEmployeeLeave(
  input: IHrEmployeeLeaveUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeLeaveUpdateDocument,
      variables: input,
    });
    return res?.data?.hrEmployeeLeaveUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function createHrEmployeeLeaveHistory(
  input: IHrEmployeeLeaveHistoryCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeLeaveHistoryCreateDocument,
      variables: input,
    });
    return res?.data?.hrEmployeeLeaveHistoryCreate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function updateHrEmployeeLeaveHistory(
  input: IHrEmployeeLeaveHistoryUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeLeaveHistoryUpdateDocument,
      variables: input,
    });
    return res?.data?.hrEmployeeLeaveHistoryUpdate ?? false;
  } catch (error) {
    throw error;
  }
}

export async function deleteHrEmployeeLeaveHistory(
  id: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeLeaveHistoryDeleteDocument,
      variables: {
        id,
      },
    });
    return res?.data?.hrEmployeeLeaveHistoryDelete ?? false;
  } catch (error) {
    throw error;
  }
}
