import { ICommonListModel, IUsersFilterResponse } from "api";
import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  HrEmployeeArchiveDocument,
  HrEmployeeGetByIdDocument,
  HrEmployeeListDocument,
  HrEmployeePaginatedDocument,
  HrEmployeeCreateDocument,
  HrEmployeeUnarchiveDocument,
  HrEmployeeUpdateDocument,
  HrEmployeeDeleteDocument,
  UsersForEmployeeCreationDocument,
  HrOfficeListDocument,
  HrEmployeeUserDetailsDocument,
} from "api/generated";

import { Optional, ScopeEnum, ResourceEnum } from "config";

import {
  IHrEmployeeModel,
  IHrEmployeeCreateVars,
  IHrEmployeePaginatedResponse,
  IHrEmployeePaginatedVars,
  IHrEmployeeUpdateVars,
  IHrEmployeeListModel,
  HrEmployeeUserDetails,
} from "./types";

export async function getHrOfficeList(): Promise<ICommonListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrOfficeListDocument,
    });
    return res?.data?.hrOfficeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getUsersForEmployeeCreate(): Promise<
  IUsersFilterResponse[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UsersForEmployeeCreationDocument,
    });
    return res?.data?.usersForEmployeeCreation ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeUserDetails(
  userId: number
): Promise<HrEmployeeUserDetails> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeUserDetailsDocument,
      variables: {
        userId,
      },
    });
    return res?.data?.hrEmployeeUserDetails;
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployee(
  id: number
): Promise<Optional<IHrEmployeeModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeGetByIdDocument,
      variables: {
        id: id,
      },
    });
    return res?.data?.hrEmployeeGetById;
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeePaginated(
  variables: IHrEmployeePaginatedVars
): Promise<Optional<IHrEmployeePaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeePaginatedDocument,
      variables,
    });
    return res?.data?.hrEmployeeListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getHrEmployeeList(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<IHrEmployeeListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: HrEmployeeListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.hrEmployeeList || [];
  } catch (error) {
    throw error;
  }
}

export async function createHrEmployee(
  variables: IHrEmployeeCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeCreateDocument,
      variables,
    });
    return Boolean(res?.data?.hrEmployeeCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateHrEmployee(
  variables: IHrEmployeeUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.hrEmployeeUpdate);
  } catch (error) {
    throw error;
  }
}

export async function archiveHrEmployee(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeArchiveDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.hrEmployeeArchive);
  } catch (error) {
    throw error;
  }
}

export async function deleteHrEmployee(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeDeleteDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.hrEmployeeDelete);
  } catch (error) {
    throw error;
  }
}

export async function unarchiveHrEmployee(id: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: HrEmployeeUnarchiveDocument,
      variables: {
        id,
      },
    });
    return Boolean(res?.data?.hrEmployeeUnarchive);
  } catch (error) {
    throw error;
  }
}
