import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IHrEmployeeLeavePaginatedResponse,
  getHrEmployeeLeaveListPaginated,
} from "api";
import { Nullable } from "config";
import { store } from "store";

import { HrEmployeeLeaveModalMode } from "./enums";
import { IHrEmployeeLeaveModalData } from "./types";

const initialState = {
  hrEmployeeLeaveData: null as Nullable<
    IHrEmployeeLeavePaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: HrEmployeeLeaveModalMode.VIEW,
    open: false,
    loading: false,
  } as IHrEmployeeLeaveModalData,
};

export const fetchHrEmployeeLeaveList = createAsyncThunk(
  "hr/fetchHrEmployeeLeaveList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getHrEmployeeLeaveListPaginated(filters);
    return response;
  }
);

const hrEmployeeLeaveSlice = createSlice({
  name: "hrEmployeeLeave",
  initialState,
  reducers: {
    setHrEmployeeLeaveModalData: (
      state,
      action: PayloadAction<IHrEmployeeLeaveModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeHrEmployeeLeaveModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setHrEmployeeLeaveModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHrEmployeeLeaveList.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        hrEmployeeLeaveData: action.payload
          ?.edges as IHrEmployeeLeavePaginatedResponse["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchHrEmployeeLeaveList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setHrEmployeeLeaveModalData,
  closeHrEmployeeLeaveModal,
  setHrEmployeeLeaveModalLoading,
} = hrEmployeeLeaveSlice.actions;
export default hrEmployeeLeaveSlice.reducer;
