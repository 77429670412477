import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getHrDocumentListPaginated, IDocumentListResponse } from "api";
import { Nullable } from "config";
import { DocumentModalMode, RootState } from "store";

import { IHrDocumentListModalData } from "./types";

const initialState = {
  documentList: null as Nullable<IDocumentListResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: DocumentModalMode.VIEW,
  } as IHrDocumentListModalData,
};

export const fetchHrDocumentList = createAsyncThunk(
  "hr-document-list/fetchHrDocumentList",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getHrDocumentListPaginated(filters);
    return response;
  }
);

const hrDocumentListSlice = createSlice({
  name: "hr-document-list",
  initialState,
  reducers: {
    setHrDocumentListModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeHrDocumentListModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHrDocumentList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        documentList: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchHrDocumentList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setHrDocumentListModalData, closeHrDocumentListModal } =
  hrDocumentListSlice.actions;
export default hrDocumentListSlice.reducer;
