import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IHrEmployeeCompensationModel,
  getHrEmployeeCompensationList,
} from "api";
import { Nullable } from "config";

import { HrEmployeeCompensationModalMode } from "./enums";
import { IHrEmployeeCompensationModalData } from "./types";

const initialState = {
  hrEmployeeCompensationData: null as Nullable<IHrEmployeeCompensationModel[]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: HrEmployeeCompensationModalMode.VIEW,
    open: false,
    loading: false,
  } as IHrEmployeeCompensationModalData,
};

export const fetchHrEmployeeCompensationList = createAsyncThunk(
  "hr/fetchHrEmployeeCompensationList",
  async () => {
    const response = await getHrEmployeeCompensationList();
    return response;
  }
);

const hrEmployeeCompensationSlice = createSlice({
  name: "hrEmployeeCompensation",
  initialState,
  reducers: {
    setHrEmployeeCompensationModalData: (
      state,
      action: PayloadAction<IHrEmployeeCompensationModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeHrEmployeeCompensationModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setHrEmployeeCompensationModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchHrEmployeeCompensationList.fulfilled,
      (state, action) => {
        if (!action.payload) return;
        return {
          ...state,
          hrEmployeeCompensationData: action.payload,
          totalCount: action.payload.length,
          loading: false,
        };
      }
    );
    builder.addCase(fetchHrEmployeeCompensationList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setHrEmployeeCompensationModalData,
  closeHrEmployeeCompensationModal,
  setHrEmployeeCompensationModalLoading,
} = hrEmployeeCompensationSlice.actions;
export default hrEmployeeCompensationSlice.reducer;
