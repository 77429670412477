import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IHrEmployeePaginatedResponse, getHrEmployeePaginated } from "api";
import { Nullable } from "config";
import { store } from "store";

import { HrEmployeeModalMode } from "./enums";
import { IHrEmployeeModalData } from "./types";

const initialState = {
  hrEmployeeData: null as Nullable<IHrEmployeePaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: HrEmployeeModalMode.VIEW,
    open: false,
    loading: false,
  } as IHrEmployeeModalData,
};

export const fetchHrEmployeeList = createAsyncThunk(
  "hr/fetchHrEmployeeList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getHrEmployeePaginated(filters);
    return response;
  }
);

const hrEmployeeSlice = createSlice({
  name: "hrEmployee",
  initialState,
  reducers: {
    setHrEmployeeModalData: (
      state,
      action: PayloadAction<IHrEmployeeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeHrEmployeeModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setHrEmployeeModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHrEmployeeList.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        hrEmployeeData: action.payload
          ?.edges as IHrEmployeePaginatedResponse["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchHrEmployeeList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setHrEmployeeModalData,
  closeHrEmployeeModal,
  setHrEmployeeModalLoading,
} = hrEmployeeSlice.actions;
export default hrEmployeeSlice.reducer;
