import { lazy } from "react";

import { RoutePaths } from "./enums";
import { IRoute } from "./types";

export const routes: IRoute[] = [
  {
    path: RoutePaths.VENTURE_ASSET_ATTRIBUTION,
    element: lazy(() => import("pages/venture/asset-attribution")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_ASSET_ATTRIBUTION}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_DASHBOARD,
    element: lazy(
      () => import("pages/employee-dashboard/components/venture-dashboard")
    ),
    protected: true,
  },
  {
    path: RoutePaths.VENTURE_PROJECT_PROFILE,
    element: lazy(() => import("pages/venture/project-profile")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_PROJECT_PROFILE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_VALUATION_CONTROL,
    element: lazy(() => import("pages/venture/valuation-control")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_VALUATION_CONTROL}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_CONTACT,
    element: lazy(() => import("pages/venture/venture-contact")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_CONTACT}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_KPI,
    element: lazy(() => import("pages/venture/venture-kpi")),
    protected: true,
  },
  {
    path: RoutePaths.VENTURE_PORTFOLIO_CONTROL,
    element: lazy(() => import("pages/venture/portfolio-control")),
    protected: true,
  },
  {
    path: RoutePaths.VENTURE_SNAPSHOTS,
    element: lazy(() => import("pages/venture/venture-snapshots")),
    protected: true,
  },
  {
    path: RoutePaths.VENTURE_CONTACT_TYPE,
    element: lazy(
      () => import("pages/administration/venture-library/contact-type")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_CONTACT_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_INVESTMENT_METHOD,
    element: lazy(
      () => import("pages/administration/venture-library/investment-method")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_INVESTMENT_METHOD}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  // {
  //   path: RoutePaths.VENTURE_PROJECT_TYPE_LIST,
  //   element: lazy(
  //     () => import("pages/administration/venture-library/project-type-list")
  //   ),
  //   protected: true,
  //   children: [
  //     {
  //       path: `${RoutePaths.VENTURE_PROJECT_TYPE_LIST}/:id` as RoutePaths,
  //       protected: true,
  //     },
  //   ],
  // },
  {
    path: RoutePaths.VENTURE_BLOCKCHAIN_LIST,
    element: lazy(
      () => import("pages/administration/venture-library/blockchain-list")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_BLOCKCHAIN_LIST}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_ORIGINATION_SOURCE,
    element: lazy(
      () => import("pages/administration/venture-library/origination-source")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_ORIGINATION_SOURCE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADMIN_EMAIL_TEMPLATES,
    element: lazy(() => import("pages/administration/email-templates")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADMIN_EMAIL_TEMPLATES}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.LINK_AGENT_RESOURCES,
    element: lazy(() => import("pages/link-agent-pages/resources")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.LINK_AGENT_RESOURCES}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADMIN_RESOURCES,
    element: lazy(
      () => import("pages/administration/user-interaction/resources")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADMIN_RESOURCES}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_SECTOR,
    element: lazy(() => import("pages/administration/venture-library/sector")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_SECTOR}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.LINK_AGENT_REPORT,
    element: lazy(() => import("pages/link-agent-pages/report")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.LINK_AGENT_REPORT}/:id` as RoutePaths,
        protected: true,
        element: lazy(
          () =>
            import(
              "pages/link-agent/link-agent-report/components/capital-introducer-report-entity/component"
            )
        ),
      },
    ],
  },
  {
    path: RoutePaths.LINK_AGENT_PAYOUT,
    element: lazy(() => import("pages/link-agent-pages/payout")),
    protected: true,
    children: (idToken) => [
      idToken?.linkAgentTypeId === 1
        ? {
            path: `${RoutePaths.LINK_AGENT_PAYOUT}/:linkAgentProfileId` as RoutePaths,
            protected: false,
            props: { isLinkAgentPayoutPage: true },
            element: lazy(
              () =>
                import(
                  "pages/link-agent/link-agent-payouts/components/payout-entity/component"
                )
            ),
            children: [
              {
                path: `${RoutePaths.LINK_AGENT_PAYOUT}/:linkAgentProfileId/:id` as RoutePaths,
                protected: false,
              },
            ],
          }
        : {
            path: `${RoutePaths.LINK_AGENT_PAYOUT}/:id` as RoutePaths,
            protected: false,
          },
    ],
  },
  {
    path: RoutePaths.LINK_AGENT_MY_INFO,
    element: lazy(() => import("pages/link-agent-pages/my-info")),
    protected: true,
  },
  {
    path: RoutePaths.VENTURE_TAGS,
    element: lazy(() => import("pages/administration/venture-library/tags")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_TAGS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_NOTE_TYPE,
    element: lazy(
      () => import("pages/administration/venture-library/note-type")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_NOTE_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.VENTURE_DOCUMENT_LIST,
    element: lazy(
      () => import("pages/administration/venture-library/document-list")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.VENTURE_DOCUMENT_LIST}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.CAPITAL_INTRODUCER_KPI_REPORT,
    element: lazy(() => import("pages/link-agent/kpi-report")),
    protected: true,
  },
  {
    path: RoutePaths.CAPITAL_INTRODUCER_REPORT,
    element: lazy(() => import("pages/link-agent/link-agent-report")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.CAPITAL_INTRODUCER_REPORT}/:id` as RoutePaths,
        protected: true,
        element: lazy(
          () =>
            import(
              "pages/link-agent/link-agent-report/components/capital-introducer-report-entity/component"
            )
        ),
      },
    ],
  },
  {
    path: RoutePaths.CAPITAL_INTRODUCER_PAYOUTS,
    element: lazy(() => import("pages/link-agent/link-agent-payouts")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.CAPITAL_INTRODUCER_PAYOUTS}/:linkAgentProfileId` as RoutePaths,
        protected: false,
        element: lazy(
          () =>
            import(
              "pages/link-agent/link-agent-payouts/components/payout-entity/component"
            )
        ),
        children: [
          {
            path: `${RoutePaths.CAPITAL_INTRODUCER_PAYOUTS}/:linkAgentProfileId/:id` as RoutePaths,
            protected: false,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.REPORT_TYPE,
    element: lazy(
      () => import("pages/administration/investors-management/report-type")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.REPORT_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.STATEMENT_TYPE,
    element: lazy(
      () => import("pages/administration/investors-management/statement-type")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.STATEMENT_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.REPORTS,
    element: lazy(() => import("pages/reports")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.REPORTS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.STATEMENTS,
    element: lazy(() => import("pages/statements")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.STATEMENTS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ANNOUNCEMENTS,
    element: lazy(
      () =>
        import(
          "pages/administration/user-interaction/events-and-updates/announcements"
        )
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ANNOUNCEMENTS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.WEEKLY_UPDATES,
    element: lazy(
      () =>
        import(
          "pages/administration/user-interaction/events-and-updates/weekly-updates"
        )
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.WEEKLY_UPDATES}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.UPCOMING_EVENTS,
    element: lazy(
      () =>
        import(
          "pages/administration/user-interaction/events-and-updates/upcoming-events"
        )
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.UPCOMING_EVENTS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.INVESTOR_CAPITAL_MOVEMENT,
    element: lazy(() => import("pages/investor-capital-movement")),
    protected: true,
  },
  {
    path: RoutePaths.INVESTOR_MY_INFO,
    element: lazy(() => import("pages/my-info")),
    protected: true,
  },
  {
    path: RoutePaths.INVESTOR_CAPITAL_MOVEMENT,
    element: lazy(() => import("pages/blank")),
    protected: true,
  },
  {
    path: RoutePaths.INVESTOR_REPORTS,
    element: lazy(() => import("pages/reports")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.INVESTOR_REPORTS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.INVESTOR_STATEMENTS,
    element: lazy(() => import("pages/statements")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.INVESTOR_STATEMENTS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.LINK_AGENT_PROFILES,
    element: lazy(() => import("pages/link-agent/link-agent-profiles")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.LINK_AGENT_PROFILES}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.CLIENT_DATABASE,
    element: lazy(() => import("pages/westeros/client-database")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.CLIENT_DATABASE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADVISORY_PROJECT,
    element: lazy(() => import("pages/advisory/advisory-project")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADVISORY_PROJECT}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADVISORY_BUYER,
    element: lazy(() => import("pages/advisory/advisory-buyer")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADVISORY_BUYER}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADVISORY_PURCHASE,
    element: lazy(() => import("pages/advisory/advisory-purchase")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADVISORY_PURCHASE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADVISORY_TRANCHE,
    element: lazy(() => import("pages/advisory/advisory-tranche")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADVISORY_TRANCHE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.FIREBLOCKS_WALLET,
    element: lazy(
      () => import("pages/administration/advisory-library/fireblocks-wallet")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.FIREBLOCKS_WALLET}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADVISORY_EXIT_PLAN,
    element: lazy(() => import("pages/advisory/advisory-exit-plan")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADVISORY_EXIT_PLAN}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADVISORY_DISTRIBUTION,
    element: lazy(() => import("pages/advisory/advisory-distribution")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.ADVISORY_DISTRIBUTION}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ADVISORY_REPORT,
    element: lazy(() => import("pages/advisory/advisory-report")),
    protected: true,
  },
  {
    path: RoutePaths.WESTEROS_SERVICE,
    element: lazy(
      () => import("pages/administration/westeros-library/service")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.WESTEROS_SERVICE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.WESTEROS_NOTE_TYPE,
    element: lazy(
      () => import("pages/administration/westeros-library/note-type")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.WESTEROS_NOTE_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.WESTEROS_DOCUMENT_LIST,
    element: lazy(
      () => import("pages/administration/westeros-library/document-list")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.WESTEROS_DOCUMENT_LIST}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.WESTEROS_TAGS,
    element: lazy(() => import("pages/administration/westeros-library/tags")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.WESTEROS_TAGS}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.SNAPSHOTS,
    element: lazy(() => import("pages/snapshots")),
    protected: true,
  },
  {
    path: RoutePaths.PRODUCT_FUND_SERIES,
    element: lazy(() => import("pages/investment/product-fund-series")),
    protected: true,
  },
  {
    path: RoutePaths.NOTE_TYPE,
    element: lazy(() => import("pages/administration/library/note-type")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.NOTE_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.KPI_PERFORMANCE,
    element: lazy(() => import("pages/investment/kpi-performance")),
    protected: true,
  },
  {
    path: RoutePaths.EXPECTED_CONVERSION,
    element: lazy(
      () => import("pages/administration/settings/expected-conversion")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.EXPECTED_CONVERSION}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.PRODUCT_FUND_REPORTING,
    element: lazy(() => import("pages/investment/product-fund-reporting")),
    protected: true,
  },
  {
    path: RoutePaths.SOURCE_EXCHANGE,
    element: lazy(() => import("pages/administration/library/source-exchange")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.SOURCE_EXCHANGE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.DOCUMENT,
    element: lazy(() => import("pages/administration/library/document")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.DOCUMENT}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.PRODUCT_FUND,
    element: lazy(() => import("pages/administration/library/product-fund")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.PRODUCT_FUND}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.TAG,
    element: lazy(() => import("pages/administration/library/tag")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.TAG}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.EMAILS,
    element: lazy(() => import("pages/investment/email-system")),
    protected: true,
    children: [
      {
        path: RoutePaths.EMAILS_SEND_EMAIL,
        protected: true,
        isAllowedStrict: true,
        element: lazy(
          () =>
            import(
              "pages/investment/email-system/components/send-email-section/component"
            )
        ),
      },
      {
        path: `${RoutePaths.EMAILS}/:id` as RoutePaths,
        protected: true,
        element: lazy(
          () =>
            import(
              "pages/investment/email-system/components/email-history-modal/component"
            )
        ),
      },
    ],
  },
  {
    path: RoutePaths.REPORTING,
    element: lazy(() => import("pages/investment/reporting")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.REPORTING}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK,
    element: lazy(() => {
      return import("pages/system/task-management/task");
    }),
    protected: true,
    children: [
      {
        path: `${RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.SYSTEM_TASK_MANAGEMENT_RECURRING_TASK,
    element: lazy(() => {
      return import("pages/system/task-management/recurring-task");
    }),
    protected: true,
    children: [
      {
        path: `${RoutePaths.SYSTEM_TASK_MANAGEMENT_RECURRING_TASK}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK_TYPE,
    element: lazy(() => {
      return import("pages/system/task-management/task-type");
    }),
    protected: true,
    children: [
      {
        path: `${RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.SYSTEM_TASK_MANAGEMENT_NOTE_TYPE,
    element: lazy(() => {
      return import("pages/system/task-management/note-type");
    }),
    protected: true,
    children: [
      {
        path: `${RoutePaths.SYSTEM_TASK_MANAGEMENT_NOTE_TYPE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK_REPORTING,
    element: lazy(() => {
      return import("pages/system/task-management/task-reporting");
    }),
    protected: true,
  },
  {
    path: RoutePaths.INVESTMENT_CAPITAL_MOVEMENT,
    element: lazy(() => import("pages/investment/capital-movement")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.INVESTMENT_CAPITAL_MOVEMENT}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.INVESTMENT_CAPITAL_MOVEMENT_DIVIDEND,
    element: lazy(() => import("pages/investment/capital-movement-dividend")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.INVESTMENT_CAPITAL_MOVEMENT_DIVIDEND}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.PROFILES,
    element: lazy(() => import("pages/investment/profiles")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.PROFILES}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.USER_MANAGEMENT,
    element: lazy(() => import("pages/administration/user-management")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.USER_MANAGEMENT}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.ASSETS_FIREBLOCKS,
    element: lazy(() => import("pages/assets/fireblocks")),
    protected: true,
  },
  {
    path: RoutePaths.ASSETS_EXCHANGE,
    element: lazy(() => import("pages/assets/exchange")),
    protected: true,
  },
  {
    path: RoutePaths.DASHBOARD,
    element: lazy(() => import("pages/dashboard")),
    protected: true,
  },
  {
    path: RoutePaths.EMPLOYEE_DASHBOARD,
    element: lazy(() => import("pages/employee-dashboard")),
    protected: true,
  },
  {
    path: RoutePaths.HOME,
    element: lazy(() => import("pages/home")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.HOMEPAGE}/:id` as RoutePaths,
        protected: false,
        element: lazy(
          () => import("pages/home/components/homepage-modal/component")
        ),
      },
    ],
  },
  {
    path: RoutePaths.HR_DOCUMENT_LIST,
    element: lazy(
      () => import("pages/administration/hr-library/document-list")
    ),
    protected: true,
    children: [
      {
        path: `${RoutePaths.HR_DOCUMENT_LIST}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.HR_EMPLOYEE,
    element: lazy(() => import("pages/hr/hr-employee")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.HR_EMPLOYEE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.HR_EMPLOYEE_LEAVE,
    element: lazy(() => import("pages/hr/hr-employee-leave")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.HR_EMPLOYEE_LEAVE}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.HR_EMPLOYEE_COMPENSATION,
    element: lazy(() => import("pages/hr/hr-employee-compensation")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.HR_EMPLOYEE_COMPENSATION}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.HR_FINANCIAL_REPORT,
    element: lazy(() => import("pages/hr/hr-financial-report")),
    protected: true,
  },
  {
    path: RoutePaths.HR_HOLIDAY_CALENDAR,
    element: lazy(() => import("pages/hr/hr-holiday-calendar")),
    protected: true,
    children: [
      {
        path: `${RoutePaths.HR_HOLIDAY_CALENDAR}/:id` as RoutePaths,
        protected: true,
      },
    ],
  },
  {
    path: RoutePaths.SIGN_IN,
    element: lazy(() => import("pages/sign-in")),
    protected: false,
  },
  {
    path: RoutePaths.NOT_FOUND,
    element: lazy(() => import("pages/404")),
    protected: false,
  },
  {
    path: RoutePaths.WILDECARD,
    element: lazy(() => import("pages/404")),
    protected: false,
  },
];
